import i18n from 'i18next';
import moment from 'moment';
import { format } from 'javascripts/typescript/utils/dateTimeFormat';
import en from './locales/en.json';
import ru from './locales/ru.json';
import es from './locales/es.json';
import ro from './locales/ro.json';
import uk from './locales/uk.json';
import bc_trc_ru from './locales/ru/bc_trc.json';

i18n.init({
  interpolation: {
    format,
  },
  resources: {
    en, ru, es, ro, uk, bc_trc_ru,
  },
  fallbackLng: {
    default: ['en'],
    ru: ['en'],
    es: ['en'],
    ro: ['en'],
    uk: ['en'],
    bc_trc_ru: ['ru'],
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

const russianPluralRole = i18n.services.pluralResolver.getRule('ru');
i18n.services.pluralResolver.addRule('bc_trc_ru', russianPluralRole);

i18n.on('languageChanged', () => {
  const language = i18n.langWithoutIndustry;
  moment.updateLocale(language, i18n.t('moment'));
  moment.locale(language);
});

Object.defineProperty(i18n, 'langWithoutIndustry', {
  get() {
    return this.language === 'bc_trc_ru' ? 'ru' : this.language;
  },
});

export default i18n;

export const humanAttributeName = (objectName, attributeName) => (
  `${i18n.t(`activerecord.attributes.${objectName}.${attributeName}`)}:`
);
