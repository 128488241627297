import moment from 'moment';
import i18n from 'i18next';

export type DateTimeFormats =
  | 'long'
  | 'short'
  | 'time'
  | 'date'
  | 'localized_date'
  | 'calendar_date'
  | 'full_with_conditioned_year';

function fullWithConditionedYear(date: moment.Moment): string {
  const isCurrentYear: boolean = moment().year() === date.year();
  const full: string = i18n.t('date-time.localized-formats.full');
  const withoutYear: string = i18n.t('date-time.localized-formats.full-without-year');

  return date.format(isCurrentYear ? withoutYear : full);
}

export function format(value: string, dateTimeFormat: DateTimeFormats): string {
  switch (dateTimeFormat) {
    case 'long':
      return moment.parseZone(value).format('DD MMMM YYYY, HH:mm');
    case 'short':
      return moment.parseZone(value).format('DD.MM.YYYY');
    case 'time':
      return moment.parseZone(value).format('HH:mm');
    case 'date':
      return moment.parseZone(value).format('DD MMMM YYYY');
    case 'localized_date':
      return moment.parseZone(value).format('LL');
    case 'calendar_date':
      return moment.parseZone(value).calendar(undefined, {
        lastWeek: 'DD.MM.YYYY HH:mm',
        sameElse: 'DD.MM.YYYY HH:mm',
      });
    case 'full_with_conditioned_year':
      return fullWithConditionedYear(moment.parseZone(value));
    default:
      return value;
  }
}
